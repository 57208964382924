.top-main {
  background-color: #fd1c70;
  text-align: center;
  padding: 1.5vh;
}

.container {
  padding: 5vh 5vw;
}
.privacy-content {
  background-color: #fff;
  padding: 4% 4%;
  box-shadow: 0 0 19px 10px #b2acac77;
  border-radius: 25px;
  flex-grow: 1;
}
.brain-img {
  text-align: center;
}

.privacy-main .text-sec a {
  color: #fd1c70;
  text-decoration: none;
}